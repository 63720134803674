const isProd = () => {
    return (window.location.hostname.includes("tercera-go"))
}
const BASE_URL = isProd() ? 'https://viewer-api.tercera-go.nl' : 'https://viewer-api.ext.xcp-np1.np.xxllnc.nl'

/**
 * Endpoints related to the viewer itself
 */
export const VIEWER_ENDPOINTS = {
    AUTHENTICATE: `${BASE_URL}/v1/viewer/int/login`,
    GET_DOMAIN_URL: `${BASE_URL}/GetDomainUrl`
};
