import React from "react";
import { service } from '../../service';
import { Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import { IMapButton } from  "./interfaces/IMapButton.interface";
import { useMapStore } from "../../stores/MapState.store";
import { ImageWMS } from "ol/source";
import { Extent } from "ol/extent";
import ImageLayer from "ol/layer/Image";

const parseBbox = (bbox: string): Extent | null => {
  const values = bbox.split(" ").map(parseFloat);
  if (values.length === 4) {
    return values;
  }
  return null; // Handle invalid BBOX format
};

const MapButton: React.FC<IMapButton> = ({ regelingId, locatie_prime_id, locatie_prime_id_all, extentUrl, CBS }) => {
const { removeLayers, addMapLayer, map } = useMapStore();

const handleClick = async () => {
  // Grab the correct URL for this CBS
  const { domainUrl } = await service.getDomainByCBS(CBS)
  const BBOX = await service.getBBOX(extentUrl);
  try {
    const wmsLayer = new ImageLayer({
      source: new ImageWMS({
        url: `${domainUrl}ASMXFiles/WMS.asmx/getStandardMap`,
        params: {
          SERVICE: "WMS",
          VERSION: "1.3.0",
          REQUEST: "GetMap",
          FORMAT: "image/png",
          TRANSPARENT: "true",
          matrixSet: "EPSG:28992",
          regelingid: regelingId,
          map: 'dsopreview',
          time: Date.now(),
          CRS: "EPSG:28992",
          STYLES: "",
          BBOX: BBOX,
          CBS: CBS,
          LAYERS:'contours',
          locatie_prime_id:locatie_prime_id,
          locatie_prime_id_all:locatie_prime_id_all
        },
      }),
    });
    
    removeLayers();
    addMapLayer(wmsLayer);

    // Fit the view to the extent of the new WMS layer
    const source = wmsLayer.getSource() as ImageWMS | null;

    if (source) {
      source.once("imageloadend", () => {
        const newLayerExtent = parseBbox(source.getParams().BBOX) as Extent;
        if (newLayerExtent) {
          map && map.getView().fit(newLayerExtent, { size: map.getSize()!, maxZoom: 18 });
        }
      });
    }

    } catch (error) {
      console.error("Error fetching WMS layer:", error);
    }
  };

  return (
    <Button onClick={handleClick} startIcon={<MapIcon />}>
      Laad kaartlaag
    </Button>
  );
};

export default MapButton;
