import axios, { AxiosError, AxiosResponse } from 'axios';
import { IAuthenticateRequest } from './interfaces/IAuthenticateRequest.interface';
import { IAuthenticateResponse } from './interfaces/IAuthenticateResponse.interface';
import { IViewerTree, IViewerTreeData } from './interfaces/IViewerTree.interface';
import { IGetDomainUrl } from './interfaces/IGetDomainUrl.interface';
import { IGetBBOX } from './interfaces/IGetBBOX.interface';
import { VIEWER_ENDPOINTS } from './constants/endpoints.constants';
import i18n from './configs/i18next.config';
import { IRequestError } from './interfaces/IRequestError.interface';
import { TreeItem, TreeItemIndex } from 'react-complex-tree';

const axiosInstance = axios.create();

/**
 * Checks if a token key is present in the query string of the current URL
 * @returns Promise.resolve<boolean> if there is a token, Promise.reject otherwise
 */
const isTokenPresent = (): Promise<boolean> => {
    const currentUrl = new URL(window.location.href);
    const hasToken = currentUrl.searchParams.has('token');
    const errorMsg = i18n.t('ERRORS.NO_TOKEN_GIVEN');

    return hasToken ? Promise.resolve(true) : Promise.reject(errorMsg);
};

/**
 * Initializes the viewer index page by starting the FME workspace that loads the viewer's data
 * after the user has been authenticated
 * @param params - The parameters required to start the FME workspace, see IAuthenticateRequest interface
 * @returns - An IAuthenticateResponse object or an exception
 */
const authenticate = async (params: IAuthenticateRequest): Promise<IAuthenticateResponse> => {
    try {
        let response: AxiosResponse<IAuthenticateResponse> = 
        await axiosInstance.post(VIEWER_ENDPOINTS.AUTHENTICATE, params);
        return response.data;
    } catch (err) {
        const error: IRequestError = (err as AxiosError).response?.data as IRequestError;
        return Promise.reject(error.message || i18n.t('ERRORS.GENERAL_ERROR'));
    }
};

/**
 * Normalizes the tree object by making sure all required children are present, no item is referencing itself, and the root object has a index
 * @param params - The entire tree object that needs to be normalized
 * @returns - The normalized tree object
 */
const normalizeTreeItems = (params: IViewerTree): Record<TreeItemIndex, TreeItem<IViewerTreeData>> => {
    const uniqueChildren: string[] = [];

    // Set the index of the root object
    const rootNode = params["root"];
    rootNode["index"] = "root";

    for (const key in params) {
        let children = params[key].children;
        for (let index = 0; index < children.length; index = index + 1) {
            const child = children[index];
            if (!uniqueChildren.includes(child)) {
                uniqueChildren.push(child);
            }

            // Remove self references
            if (child === key) {
                let filtered = params[key].children.filter((item) => item !== child);
                params[key].children = filtered;
            }
        }
    }

    // If a child is not in the entire object, add it
    // for (let index = 0; index < uniqueChildren.length; index = index + 1) {
    //     let child = uniqueChildren[index];
    //     if (params[child] === undefined) {  
    //         params[child] = {
    //             index: child,
    //             isFolder: false,
    //             canMove: false,
    //             canRename: false,
    //             children: [],
    //             data: { 
    //                 title: child, 
    //                 text: null,
    //                 levelId: null,
    //                 geo: {
    //                     extent: "",
    //                     locatie_prime_id: []
    //                 }
    //             }
    //         };
    //     }
    // }

    return params as Record<TreeItemIndex, TreeItem<IViewerTreeData>>;
};

/**
 * Normalizes the index of an item so it can be used as id
 */
const normalizeItemIndex = (params: { index: string | null | TreeItemIndex }): string => {
    return params.index?.toString().replaceAll(".", "") || "";
}

/**
 * Queries the API to get the domain based off a CBS number.
 * @param CBS - The CBS number associated with the needed domain.
 * @returns - An IGetDomainUrl object or an exception
 */
const getDomainByCBS = async (CBS: string): Promise<IGetDomainUrl> => {
    try {
        let response: AxiosResponse<IGetDomainUrl> = 
        await axiosInstance.get(`${VIEWER_ENDPOINTS.GET_DOMAIN_URL}?CBSvalue=${CBS}`);
        return response.data;
    } catch (err) {
        const error: IRequestError = (err as AxiosError).response?.data as IRequestError;
        return Promise.reject(error.message || i18n.t('ERRORS.GENERAL_ERROR'));
    }
};

/**
 * Queries the API to get the domain based off a CBS number.
 * @param extentUrl - The API URL required to get BBOX.
 * @returns - An IGetBBOX object or an exception
 */
const getBBOX = async (extentUrl: string): Promise<IGetBBOX> => {
    try {
        let response: AxiosResponse<IGetBBOX> = await axiosInstance.post(`${extentUrl}`);
        return response.data;
    } catch (err) {
        const error: IRequestError = (err as AxiosError).response?.data as IRequestError;
        return Promise.reject(error.message || i18n.t('ERRORS.GENERAL_ERROR'));
    }
};

export const service ={
    isTokenPresent,
    authenticate,
    normalizeTreeItems,
    normalizeItemIndex,
    getDomainByCBS,
    getBBOX
};
